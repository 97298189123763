<template>
  <div>
    <el-form :inline="true" :model="form" ref="form">
      <el-form-item label="审核状态：">
        <el-checkbox-group v-model="form.status" @change="handleCheckedProcessChange">
          <el-checkbox v-for="(item, i) in process" :key="i" :label="item.value" :value="item.value">{{
            item.label
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </el-form-item>
      <el-form-item label="商户名称：">
        <el-select clearable v-model="form.merchant_no" filterable placeholder="请选择" style="width: 300px">
          <el-option
            v-for="item in merchantOptions"
            :key="item.merchant_no"
            :label="item.merchant_name"
            :value="item.merchant_no"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="init">查询</el-button>
        <!-- <el-button type="primary" plain @click="() => $refs['form'].resetFields()">重置</el-button> -->
      </el-form-item>
    </el-form>
    <div style="width: 100%; text-align: right">
      <router-link to="/merchant/data-review"><el-button type="primary">商户进件</el-button></router-link>
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column label="商户名称">
        <template slot-scope="{ row }">{{ row.merchant.short_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="展业地点">
        <template slot-scope="{ row }">
          <template v-if="row.merchant.country_code === 'NZL'">新西兰</template>
          <template v-else-if="row.merchant.country_code === 'HKG'">中国香港特别行政区</template>
          <template v-else-if="row.merchant.country_code === 'AUS'">澳大利亚</template>
          <template v-else-if="row.merchant.country_code === 'USA'">美国</template>
          <template v-else-if="row.merchant.country_code === 'PHL'">菲律宾</template>
          <template v-else>{{ row.merchant.country_code || '-' }}</template>
        </template>
      </el-table-column>
      <el-table-column label="代理商">
        <template slot-scope="{ row }">{{ agent_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="状态">
        <!-- 流程 START:初始化  KYC:风控初审  SECONDKYC:风控复审  CONTRACT:提交合同中  OPERATOR:运营审核  ACTIVE:已开通 ABOLITION: 拒绝开通 
         OPENING:;CRASHER1 财务1；CRASHER2 财务2； -->
        <template slot-scope="{ row }">
          <template v-if="row.status === 'KYC'">风控初审中</template>
          <template v-else-if="row.status === 'SECONDKYC'">风控复审中</template>
          <template v-else-if="row.status === 'CONTRACT'">提交合同中</template>
          <template v-else-if="row.status === 'OPERATOR'">合同审核中</template>
          <template v-else-if="row.status === 'ACTIVE'">商户已开通</template>
          <template v-else-if="row.status === 'ABOLITION'">已拒绝开通</template>
          <template v-else-if="row.status === 'START'">风控已驳回</template>
          <template v-else>-</template>
          <span v-if="row.pointer === 1 && row.deny === 1" class="red">被驳回</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="{ row }">{{ row.modified || row.created || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <router-link
            v-if="row.status === 'CONTRACT'"
            :to="{ name: 'Contract', query: { merchant_no: row.draft_no, status: row.status } }"
            >提交合同</router-link
          >
          <!-- 如果是风控已驳回 -->
          <!-- <router-link
            v-else-if="row.status === 'START'"
            :to="{ name: 'MerchantDetail', query: { merchant_no: row.draft_no, status: row.status } }"
            >查看</router-link
          > -->
          <router-link
            v-else
            :to="{ name: 'MerchantDetail', query: { merchant_no: row.draft_no, agent_name: agent_name } }"
            >查看</router-link
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" @init="init" ref="pagination" />
  </div>
</template>

<script>
import { scheduleList, merchantList } from '@/api/api'
import Pagination from '@/components/pagination'

export default {
  name: 'MerchantList', //商户列表
  components: { Pagination },
  data() {
    return {
      form: {
        status: ['kyc', 'second_kyc', 'deny', 'contract', 'operator', 'abolition', 'active'],
        // status: ['kyc', 'second_kyc', 'deny', 'contract', 'operator', 'abolition', 'active'],
        merchant_no: this.$route.query.merchant_no || null
      },
      merchantOptions: [],
      checkAll: true,
      process: [
        { label: '风控初审中', value: 'kyc' },
        { label: '风控复审中', value: 'second_kyc' },
        { label: '风控已驳回', value: 'deny' },
        { label: '提交合同中', value: 'contract' },
        { label: '合同审核中', value: 'operator' },
        { label: '已拒绝开通', value: 'abolition' },
        { label: '商户已开通', value: 'active' }
      ],
      tableData: [],
      agent_name: '',
      total: 0
    }
  },
  mounted() {
    this.merchantList()
  },
  methods: {
    init() {
      const params = {
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit,
        merchant_no: this.form.merchant_no,
        status: this.form.status.join(',')
      }
      scheduleList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.agent_name = res.data.agent_name
          this.total = res.data.count
        }
      })
    },
    // 商户列表
    merchantList() {
      merchantList().then((res) => {
        if (res.code === 200) {
          this.merchantOptions = res.data
          // this.form.merchant_no = res.data[0].merchant_no
          // this.form.merchant_no = this.$route.query.merchant_no || res.data[0].merchant_no
          this.init()
        }
      })
    },
    // 全选
    handleCheckAllChange(val) {
      if (val) {
        this.process.forEach((val) => {
          this.form.status.push(val.value)
        })
      } else {
        this.form.status = []
      }
    },
    // 点击复选框
    handleCheckedProcessChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.process.length
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

